import { FunctionComponent, useEffect, useMemo, useState, SyntheticEvent } from 'react';
import { ConnectedComponent, connect } from 'react-redux';
import GenericTopTableCGFCCU from '../components/GenericTopTableCGFCCU';
import { formatStringNumber, spanishMonthNames } from '../utils/utils';
import { AuthState, EntitiesState } from '../reducers/types';
import { FareDifference, modules, UserTypeValues } from '../types';
import { AppState } from '../store';
import moment from 'moment';
import {
  DateAndGlobalZoneFiltersConnected,
  DateAndSelectFilter,
  RefreshPolynomial
} from '../components/SecondaryButton';
import { getUncatchEndpointData } from '../utils/request';
import { CardAlertProps } from '../components/CardAlert';
import { SignalCellularAltOutlined } from '@mui/icons-material';
import BackButton from '../components/BackButton';
import * as E from '../entities';
import {
  ContingencyWithholdingCRUD,
  FareDifferenceCRUD,
  FareDifferenceSummaryCRUD,
  EstimatedClosingPortingCRUD,
  FreightProvisionCRUD,
  ReportUENCRUD,
  PolynomialCRUD
} from '../components/GeneratedComponents';
import FareDifferenceExport, {
  FareDifferenceExportDataType
} from '../components/FareDifferenceExport';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import ReportUENExport, { ReportUENExportDataType } from '../components/ReportUENExport';
import '../css/fareDifferencePage.css';
import '../css/contingencyWithHolding.css';
import '../css/reportUEN.css';
import '../css/polynomial.css';

const ContingencyWithHoldingReport: FunctionComponent<{
  auth: AuthState;
  entities: EntitiesState;
}> = ({ auth, entities }) => {
  type ListType = {
    id: string;
    name: string;
  };
  type CarrierListType = {
    carrierId: string;
    carrierName: string;
  };
  const [arrowName, setArrowName] = useState<string>('');
  const [globalDataQuantity, setGlobalDataQuantity] = useState<number>(0);
  const [carrierList, setCarrierList] = useState<Array<ListType>>([]);
  const [selected, setSelected] = useState<string>('');
  const [totalContingencyWithholding, setTotalContingencyWithholding] = useState<number>(0);

  useEffect(() => {
    const update = async () => {
      const res = await getUncatchEndpointData({
        endpoint: 'contingencyWithholding/total',
        query: { selected }
      });
      if (res && res.length > 0) {
        if (res[0].total) {
          setTotalContingencyWithholding(Number(res[0].total));
        } else {
          setTotalContingencyWithholding(0);
        }
      }
    };
    update();
  }, [selected]);

  useEffect(() => {
    const update = async () => {
      const res = await getUncatchEndpointData({
        endpoint: 'contingencyWithholding/distinctCarriers'
      });
      if (res && res.length > 0) {
        res.unshift({ carrierId: 'all', carrierName: 'Todos' });

        const data: Array<ListType> = res.map((row: CarrierListType) => ({
          id: row.carrierId,
          name: row.carrierName
        }));
        setCarrierList(data);
      }
    };
    update();
  }, []);

  useEffect(() => {
    if (auth.user) {
      const value = auth?.user?.userTypeName.includes(UserTypeValues.boss)
        ? UserTypeValues.management
        : auth?.user?.zoneGlobalDescrp.toString();
      setArrowName(value);
    }
    setGlobalDataQuantity(entities.contingencyWithholding.list.length);
  }, [auth, entities]);

  return (
    <div className='contingency-withholding-container'>
      <BackButton route='/porteo' />
      <GenericTopTableCGFCCU
        titleTable='Retención por Contingencia'
        arrowName={arrowName}
        cardAlerts={[
          {
            title: 'Datos Globales',
            icon: <SignalCellularAltOutlined fontSize='large' />,
            numberInfo: formatStringNumber(globalDataQuantity.toString())
          },
          {
            title: 'Total General',
            numberInfo: formatStringNumber(totalContingencyWithholding.toString())
          }
        ]}
      />
      <div className='contingency-withholding-filters'>
        <DateAndSelectFilter
          selectList={carrierList}
          viewsDate={['year']}
          selectLabel='Transportista'
          webEntity={E.ContingencyWithholdingEntity}
          setSelectFiltered={setSelected}
        />
      </div>
      <div className='contingency-withholding-crud'>
        <ContingencyWithholdingCRUD />
      </div>
    </div>
  );
};

export const ContingencyWithHoldingReportConnected = connect(
  ({ auth, entities }: AppState) => ({ auth, entities }),
  {}
)(ContingencyWithHoldingReport);

const FareDifferenceReport: FunctionComponent<{
  entrepreneurs: boolean;
  auth: AuthState;
  entities: EntitiesState;
}> = ({ entrepreneurs, auth, entities }) => {
  const webEntity = useMemo(
    () => (entrepreneurs ? E.FareDifferenceEntrepreneursEntity : E.FareDifferenceEntity),
    [entrepreneurs]
  );
  const today = new Date();
  const [arrowName, setArrowName] = useState<string>('');
  const [dateFiltered, setDateFiltered] = useState<moment.Moment>(moment(today));
  const [selectFiltered, setSelectFiltered] = useState<string>('');
  const [cardAlerts, setCardAlerts] = useState<Array<CardAlertProps>>([]);
  const [freightCharges, setFreightCharges] = useState<{
    q1: number;
    q2: number;
    total: number;
  }>({ q1: 0, q2: 0, total: 0 });
  const [fareDifference, setFareDifference] = useState<{
    diff: number;
    diffPercentage: number;
  }>({ diff: 0, diffPercentage: 0 });

  useEffect(() => {
    if (auth.user) {
      const value = auth?.user?.userTypeName.includes(UserTypeValues.boss)
        ? UserTypeValues.management
        : auth?.user?.zoneGlobalDescrp.toString();
      setArrowName(value);
    }
  }, [auth, entities]);

  const spanishMonth = useMemo(
    () => spanishMonthNames[dateFiltered ? dateFiltered.month() : new Date().getMonth()],
    [dateFiltered]
  );

  const year = useMemo(
    () => (dateFiltered ? dateFiltered.year() : new Date().getFullYear()),
    [dateFiltered]
  );

  const title = useMemo(() => {
    return `${spanishMonth} ${year}`;
  }, [spanishMonth, year]);

  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return FareDifferenceCRUD(webEntity, {});
  }, [webEntity]);

  type ListType = {
    id: string;
    name: string;
  };
  type CarrierListType = {
    carrierId: string;
    carrierName: string;
  };

  const [carrierList, setCarrierList] = useState<Array<ListType>>([]);

  useEffect(() => {
    const update = async () => {
      const res = await getUncatchEndpointData({
        endpoint: 'fareDifference/distinctCarriers',
        query: { entrepreneurs }
      });
      if (res && res.length > 0) {
        res.unshift({ carrierId: 'all', carrierName: 'Todos' });

        const data: Array<ListType> = res.map((row: CarrierListType) => ({
          id: row.carrierId,
          name: row.carrierName
        }));
        setCarrierList(data);
      }
    };
    update();
  }, [entrepreneurs]);

  useEffect(() => {
    const update = async () => {
      const res = await getUncatchEndpointData({
        endpoint: 'fareDifference/freightCharges',
        query: {
          filters: { date: dateFiltered.format('YYYY-MM-DD'), selected: selectFiltered },
          entrepreneurs
        }
      });
      if (res && res.length > 0) {
        const q1 = Number(res[0].q1);
        const q2 = Number(res[0].q2);
        const totalFreightCharges = q1 + q2;

        const resFreightCharges = {
          q1,
          q2,
          total: totalFreightCharges
        };
        setFreightCharges(resFreightCharges);
      }
    };
    update();
  }, [dateFiltered, selectFiltered, entrepreneurs]);

  useEffect(() => {
    const fareDifference = entities[webEntity.name].list as FareDifference[];
    const totalTariff = fareDifference.reduce(
      (acc, obj) => (obj.total ? acc + Number(obj.total) : acc),
      0
    );
    const diffFreightCharges = totalTariff - freightCharges.total;
    const diffPercentageFreightCharges =
      freightCharges.total !== 0 ? (diffFreightCharges / freightCharges.total) * 100 : 0;
    setFareDifference({
      diff: diffFreightCharges,
      diffPercentage: diffPercentageFreightCharges
    });
  }, [freightCharges, entities, webEntity]);

  useEffect(() => {
    setCardAlerts([
      {
        title: `1Q ${spanishMonth}`,
        detailStyle: { fontSize: '15px' },
        numberInfo: formatStringNumber(freightCharges.q1.toString())
      },
      {
        title: `2Q ${spanishMonth}`,
        detailStyle: { fontSize: '15px' },
        numberInfo: formatStringNumber(freightCharges.q2.toString())
      },
      {
        title: `Total Flete ${spanishMonth}`,
        detailStyle: { fontSize: '15px' },
        numberInfo: formatStringNumber(freightCharges.total.toString())
      },
      {
        title: 'Diferencia',
        detailStyle: { fontSize: '15px' },
        variant:
          fareDifference.diffPercentage > 5 || fareDifference.diffPercentage < 0
            ? 'error'
            : undefined,
        numberInfo: `${formatStringNumber(
          fareDifference.diff.toString(),
          0,
          0
        )} ${formatStringNumber(fareDifference.diffPercentage.toString(), 0, 2)}%`
      }
    ]);
  }, [dateFiltered, spanishMonth, freightCharges, fareDifference]);

  const [dataToExport, setDataToExport] = useState<Array<FareDifferenceExportDataType>>([]);

  useEffect(() => {
    if (entities[webEntity.name].list.length > 0) {
      const entityData = entities[webEntity.name].list as FareDifference[];
      const data: FareDifferenceExportDataType[] = entityData.map((obj) => ({
        zonePortingDescription: obj.zonePortingDescription,
        carrierName: obj.carrierName,
        tariffTotal: Number(obj.tariffTotal),
        boxQuantity: Number(obj.boxQuantity),
        total: Number(obj.total)
      }));
      setDataToExport(data);
    }
  }, [entities, webEntity]);

  return (
    <div className='fare-difference-container'>
      <GenericTopTableCGFCCU titleTable={title} arrowName={arrowName} cardAlerts={cardAlerts} />
      <div className='fare-difference-filter-container'>
        <FareDifferenceExport
          month={spanishMonth}
          year={year.toString()}
          data={dataToExport}
          freightCharges={{
            q1: freightCharges.q1,
            q2: freightCharges.q2
          }}
        />
        <DateAndSelectFilter
          selectList={carrierList}
          viewsDate={['year', 'month']}
          selectLabel='Transportista'
          webEntity={webEntity}
          setDateFiltered={setDateFiltered}
          setSelectFiltered={setSelectFiltered}
        />
      </div>
      <CRUD />
    </div>
  );
};

export const FareDifferenceReportConnected = connect(
  ({ auth, entities }: AppState) => ({ auth, entities }),
  {}
)(FareDifferenceReport);

export const FareDifferenceSummaryReport = () => {
  return <FareDifferenceSummaryCRUD />;
};

export const EstimatedClosingPortingReport: FunctionComponent = () => {
  const [value, setValue] = useState('1');
  const handleChange = (_: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
        gap: '10px'
      }}>
      <div>
        <BackButton route='/porteo' />
      </div>

      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleChange}
            textColor='inherit'
            TabIndicatorProps={{
              style: {
                backgroundColor: '#64a70b'
              }
            }}>
            <Tab label='Estado Financiero' value='1' />
            <Tab label='Provision' value='2' />
          </TabList>
        </Box>
        <TabPanel value='1'>
          <EstimatedClosingPortingCRUD />
        </TabPanel>
        <TabPanel value='2'>
          <FreightProvisionCRUD />
        </TabPanel>
      </TabContext>
    </div>
  );
};

const ReportUENCRUDComponent: FunctionComponent<{
  auth: AuthState;
  entities: EntitiesState;
}> = ({ auth, entities }) => {
  const today = new Date();
  const [arrowName, setArrowName] = useState<string>('');
  const [globalDataQuantity, setGlobalDataQuantity] = useState<number>(0);
  const [dateFiltered, setDateFiltered] = useState<moment.Moment>(moment(today));

  type ListType = {
    id: string;
    name: string;
  };
  type CarrierListType = {
    carrierId: string;
    carrierName: string;
  };

  const [carrierList, setCarrierList] = useState<Array<ListType>>([]);

  useEffect(() => {
    const update = async () => {
      const res = await getUncatchEndpointData({
        endpoint: 'extraCarriage/reportUEN/distinctCarriers'
      });
      if (res && res.length > 0) {
        res.unshift({ carrierId: 'all', carrierName: 'Todos' });

        const data: Array<ListType> = res.map((row: CarrierListType) => ({
          id: row.carrierId,
          name: row.carrierName
        }));
        setCarrierList(data);
      }
    };
    update();
  }, []);

  const [dataToExport, setDataToExport] = useState<Array<ReportUENExportDataType>>([]);

  useEffect(() => {
    if (entities.reportUEN.list.length > 0) {
      const entityData = entities.reportUEN.list;
      const data: ReportUENExportDataType[] = entityData.map((obj) => ({
        carrierRut: obj.carrierRut,
        carrierName: obj.carrierName,
        netAmount: Number(obj.netAmount),
        grossAmount: Number(obj.grossAmount),
        gloss: obj.gloss
      }));
      setDataToExport(data);
    } else {
      setDataToExport([]);
    }
  }, [entities]);

  useEffect(() => {
    if (auth.user) {
      const value = auth?.user?.userTypeName.includes(UserTypeValues.boss)
        ? UserTypeValues.management
        : auth?.user?.zoneGlobalDescrp.toString();
      setArrowName(value);
    }
    setGlobalDataQuantity(entities.reportUEN.list.length);
  }, [auth, entities]);

  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return ReportUENCRUD({
      date: dateFiltered.format('YYYY-MM-DD')
    });
  }, [dateFiltered]);

  const title = useMemo(() => {
    const month = dateFiltered ? dateFiltered.month() : new Date().getMonth();
    const year = dateFiltered ? dateFiltered.year() : new Date().getFullYear();

    return `Reporte UEN ${spanishMonthNames[month]} ${year}`;
  }, [dateFiltered]);

  return (
    <div className='report-uen-container'>
      <div>
        <BackButton route='/acarreo' />
      </div>
      <div className='report-uen-crud-container'>
        <GenericTopTableCGFCCU
          titleTable={title}
          arrowName={arrowName}
          cardAlerts={[
            {
              title: 'Datos Globales',
              icon: <SignalCellularAltOutlined fontSize='large' />,
              numberInfo: formatStringNumber(globalDataQuantity.toString())
            }
          ]}
        />
        <div className='report-uen-filter-container'>
          <ReportUENExport
            month={spanishMonthNames[dateFiltered.month()]}
            year={dateFiltered.year().toString()}
            data={dataToExport}
          />
          <DateAndSelectFilter
            selectList={carrierList}
            viewsDate={['year', 'month']}
            selectLabel='Transportista'
            webEntity={E.ReportUENEntity}
            dateFiltered={dateFiltered}
            setDateFiltered={setDateFiltered}
          />
        </div>
        <CRUD />
      </div>
    </div>
  );
};

export const ReportUENCRUDComponentConnected = connect(({ auth, entities }: AppState) => ({
  auth,
  entities
}))(ReportUENCRUDComponent);

const PolynomialCRUDComponent: FunctionComponent<{
  auth: AuthState;
  entities: EntitiesState;
}> = ({ auth, entities }) => {
  const today = new Date();
  const [arrowName, setArrowName] = useState<string>('');
  const [dateFiltered, setDateFiltered] = useState<moment.Moment>(moment(today));

  type PercentageType = {
    title: string;
    value: string;
  };

  const [foreignExchangePercentages, setForeignExchangePercentages] = useState<PercentageType[]>();
  const [additionalCardAlerts, setAdditionalCardAlerts] = useState<CardAlertProps[]>([]);

  useEffect(() => {
    const update = async () => {
      const res: PercentageType[] = await getUncatchEndpointData({
        endpoint: 'foreignExchange/foreignExchangePercentages',
        query: { moduleType: modules.ACARREO.abbreviation }
      });
      if (res) {
        setForeignExchangePercentages(res);
      }
    };
    update();
  }, []);

  useEffect(() => {
    if (foreignExchangePercentages && foreignExchangePercentages.length > 0) {
      const data = foreignExchangePercentages.map((row) => {
        return {
          title: row.title,
          numberInfo: row.value
        };
      });

      setAdditionalCardAlerts(data);
    } else {
      setAdditionalCardAlerts([]);
    }
  }, [foreignExchangePercentages]);

  const [polynomialPercentage, setPolynomialPercentage] = useState<number>(0);

  useEffect(() => {
    const update = async () => {
      const res = await getUncatchEndpointData({
        endpoint: 'polynomial/polynomialPercentage',
        query: { date: dateFiltered.format('YYYY-MM-DD') }
      });
      if (res && res.length > 0) {
        setPolynomialPercentage(Number(res[0].polynomialPercentage));
      } else {
        setPolynomialPercentage(0);
      }
    };
    update();
  }, [dateFiltered]);

  useEffect(() => {
    if (auth.user) {
      const value = auth?.user?.userTypeName.includes(UserTypeValues.boss)
        ? UserTypeValues.management
        : auth?.user?.zoneGlobalDescrp.toString();
      setArrowName(value);
    }
  }, [auth]);

  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    return PolynomialCRUD({
      date: dateFiltered.format('YYYY-MM-DD')
    });
  }, [dateFiltered]);

  const title = useMemo(() => {
    const month = dateFiltered ? dateFiltered.month() : new Date().getMonth();
    const year = dateFiltered ? dateFiltered.year() : new Date().getFullYear();

    return `Polinomio ${spanishMonthNames[month]} ${year}`;
  }, [dateFiltered]);

  const [value, setValue] = useState('1');
  
  const handleChange = (_: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div className='polynomial-report-container'>
      <div className='polynomial-report-buttons-container'>
        <BackButton route='/acarreo' />
        <RefreshPolynomial dateFiltered={dateFiltered} setDateFiltered={setDateFiltered} />
      </div>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleChange}
            textColor='inherit'
            TabIndicatorProps={{
              style: {
                backgroundColor: '#64a70b'
              }
            }}>
            <Tab label='Polinomio mensual' value='1' />
            <Tab label='Polinomio trimestral' value='2' />
          </TabList>
        </Box>
        <TabPanel value='1'>
          <GenericTopTableCGFCCU
            titleTable={title}
            arrowName={arrowName}
            cardAlerts={[
              ...additionalCardAlerts,
              {
                title: 'Polinomio',
                numberInfo: `${formatStringNumber((polynomialPercentage * 100).toString(), 0, 2)} %`
              }
            ]}
          />
          <div className='polynomial-crud-filter-container'>
            <DateAndGlobalZoneFiltersConnected
              webEntity={E.PolynomialEntity}
              viewsDate={['year', 'month']}
              dateFiltered={dateFiltered}
              setDateFiltered={setDateFiltered}
              omitZoneFilter={true}
            />
          </div>
          <CRUD /> 
        </TabPanel>
        <TabPanel value='2'>
          <br/>
        </TabPanel>
      </TabContext>
    </div>
  );
};

export const PolynomialCRUDComponentConnected = connect(({ auth, entities }: AppState) => ({
  auth,
  entities
}))(PolynomialCRUDComponent);
