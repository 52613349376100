import { Fragment, FunctionComponent, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { Alert, Box, Stack, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { columns as reporteGastosColumns } from '../entities/ReporteGastosEntity';
import { EntitiesState } from '../reducers/types';
import { getUncatchEndpointData } from '../utils/request';
import { ReportePieChart, ReporteGastos as ReporteGastosType, SimpleObject } from '../types';
import { dateFormatFn, formatStringNumber, spanishMonthNames } from '../utils/utils';
import { ExtendedColumnDef, WebEntity } from '../entities/types';
import { connect, ConnectedComponent, useDispatch } from 'react-redux';
import {
  GastosPieChartComparisonCRUD,
  GastosPieChartCRUD,
  ReporteGastosCRUD
} from '../components/GeneratedComponents';
import BackButton from '../components/BackButton';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';
import { snakeToCamelCase } from '../utils/convert';
import { AppState } from '../store';
import * as E from '../entities';
import { PieChart } from '@mui/x-charts/PieChart';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
import { loadEntityDataAction } from '../actions/entitiesActions';
import {
  BarPlot,
  ChartsXAxis,
  ResponsiveChartContainer,
  ChartsTooltip,
  LinePlot,
  ChartsYAxis,
  ChartsLegend,
  AllSeriesType
} from '@mui/x-charts';
import { DatasetType } from '@mui/x-charts/internals';
import ExportContainer from '../components/ExportContainer';
import '../css/reporteGastos.css';

const MaterialUISwitch = styled(Switch)(() => ({
  width: 70,
  height: 36,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      transform: 'translateX(30px)',
      '& .MuiSwitch-thumb:before': {
        color: '#fff',
        fontWeight: 900,
        content: "'CC'",
        width: '100%',
        height: '100%',
        left: '70%',
        top: '70%',
        transform: 'translate(-50%,-50%)'
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#aab4be'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#64a70b',
    width: 34,
    height: 34,
    '&::before': {
      color: '#fff',
      fontWeight: 900,
      content: "'Zona'",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: '55%',
      top: '70%',
      transform: 'translate(-50%,-50%)'
    }
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#aab4be',
    borderRadius: 20 / 2
  }
}));

interface DropdownProps extends DropdownItemProps {
  value: string | number;
  text: string;
}

const ReporteGastosComponent: FunctionComponent<{
  entities: EntitiesState;
}> = ({ entities }) => {
  const [value, setValue] = useState('1');

  const handleChange = (_: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div className='reporte-gastos-container'>
      <div>
        <BackButton route='/reporteria' />
      </div>

      <h2 className='reporte-gastos-title'>Dinámica de Gastos</h2>

      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleChange}
            textColor='inherit'
            TabIndicatorProps={{
              style: {
                backgroundColor: '#64a70b'
              }
            }}>
            <Tab label='Gastos' value='1' />
            <Tab label='Grafico Torta' value='2' />
          </TabList>
        </Box>
        <TabPanel value='1'>
          <GastosComponent entities={entities} />
        </TabPanel>
        <TabPanel value='2'>
          <PieChartComponent entities={entities} />
        </TabPanel>
      </TabContext>
    </div>
  );
};

export const ReporteGastos = connect(({ entities }: AppState) => ({
  entities
}))(ReporteGastosComponent);

type DataType = {
  [key: string]: string | number;
};

const GastosComponent: FunctionComponent<{ entities: EntitiesState }> = ({ entities }) => {
  const [zonaIsDependent, setZonaIsDependent] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [columnasList, setColumnasList] = useState<Array<DropdownProps>>([]);
  const [indicatorsList, setIndicatorsList] = useState<Array<DropdownProps>>([]);
  const [ccList, setCcList] = useState<Array<DropdownProps>>([]);
  const [zonaList, setZonaList] = useState<Array<DropdownProps>>([]);
  const [categoriaList, setCategoriaList] = useState<Array<DropdownProps>>([]);
  const [descripCuentaList, setDescripCuentaList] = useState<Array<DropdownProps>>([]);
  const [mesList, setMesList] = useState<Array<DropdownProps>>([]);
  const [selectedColumnas, setSelectedColumnas] = useState<Array<DropdownProps>>([]);
  const [selectedIndicators, setSelectedIndicators] = useState<Array<DropdownProps>>([]);
  const [selectedCc, setSelectedCc] = useState<Array<DropdownProps>>([]);
  const [selectedZona, setSelectedZona] = useState<Array<DropdownProps>>([]);
  const [selectedCategoria, setSelectedCategoria] = useState<Array<DropdownProps>>([]);
  const [selectedDescripCuenta, setSelectedDescripCuenta] = useState<Array<DropdownProps>>([]);
  const [selectedMes, setSelectedMes] = useState<Array<DropdownProps>>([]);

  useEffect(() => {
    setLoading(true);
    const colums = reporteGastosColumns.map((obj, idx) => ({
      key: `col_${idx}`,
      value: obj.accessorKey as string,
      text: obj.header
    }));
    setColumnasList(colums);
    setSelectedColumnas(colums.slice(0, 3));

    (async () => {
      const indicators = await getUncatchEndpointData({
        endpoint: 'reportes/gastos/indicadores'
      });
      if (indicators && indicators.length > 0) {
        const data: DropdownProps[] = indicators.map((row: SimpleObject, idx: number) => ({
          key: `indicator_${idx}`,
          value: row.columnName,
          text: row.indicador
        }));
        setIndicatorsList(data);
        const currentYear = new Date().getFullYear();
        const inititalIndicators = data.filter((d) => d.text.includes(currentYear.toString()));
        setSelectedIndicators(inititalIndicators);
      }
    })();
    (async () => {
      const res = await getUncatchEndpointData({
        endpoint: 'reportes/gastos/categoria'
      });
      if (res && res.length > 0) {
        const data: DropdownProps[] = res.map((row: SimpleObject, idx: number) => ({
          key: `categoria_${idx}`,
          value: row.categoriesId,
          text: row.categoriesName
        }));
        setCategoriaList(data);
      }
    })();

    setMesList(
      spanishMonthNames.map((monthName, idx) => ({
        key: `mes_${idx}`,
        value: idx,
        text: monthName
      }))
    );
    const currentMonth = new Date().getMonth();
    const findCurrentMonth = spanishMonthNames[currentMonth];
    setSelectedMes(
      findCurrentMonth
        ? [{ key: `mes_${currentMonth}`, value: currentMonth, text: findCurrentMonth }]
        : []
    );
    setLoading(false);
  }, []);

  useEffect(() => {
    const fetchZonas = async () => {
      setLoading(true);
      if (zonaIsDependent) {
        setZonaList([]);
        const res = await getUncatchEndpointData({ endpoint: 'reportes/gastos/zona' });

        if (res && res.length > 0) {
          const data: DropdownProps[] = res.map((row: SimpleObject, idx: number) => ({
            key: `zona_${idx}`,
            value: row.zoneId,
            text: row.zoneDescription
          }));
          setZonaList(data);
        }
      } else if (selectedCc.length > 0) {
        setZonaList([]);
        const res = await getUncatchEndpointData({
          endpoint: 'reportes/gastos/zona',
          query: { selectedCC: selectedCc.map((obj) => obj.value) }
        });

        if (res && res.length > 0) {
          const data: DropdownProps[] = res.map((row: SimpleObject, idx: number) => ({
            key: `zona_${idx}`,
            value: row.zoneId,
            text: row.zoneDescription
          }));
          setZonaList(data);
        }
      }
      setLoading(false);
    };

    fetchZonas();
  }, [zonaIsDependent, selectedCc]);

  useEffect(() => {
    const fetchCC = async () => {
      setLoading(true);
      if (!zonaIsDependent) {
        setCcList([]);
        const res = await getUncatchEndpointData({ endpoint: 'reportes/gastos/cc' });

        if (res && res.length > 0) {
          const data: DropdownProps[] = res.map((row: SimpleObject, idx: number) => ({
            key: `cc_${idx}`,
            value: row.costCenterId,
            text: `${row.costCenterSap} - ${row.costCenterName}`
          }));
          setCcList(data);
        }
      } else if (selectedZona.length > 0) {
        setCcList([]);
        const res = await getUncatchEndpointData({
          endpoint: 'reportes/gastos/cc',
          query: { selectedZona: selectedZona.map((obj) => obj.value) }
        });

        if (res && res.length > 0) {
          const data: DropdownProps[] = res.map((row: SimpleObject, idx: number) => ({
            key: `cc_${idx}`,
            value: row.costCenterId,
            text: `${row.costCenterSap} - ${row.costCenterName}`
          }));
          setCcList(data);
        }
      }
      setLoading(false);
    };

    fetchCC();
  }, [zonaIsDependent, selectedZona]);

  // Clean selected values when dependency changes
  useEffect(() => {
    setSelectedCc([]);
    setSelectedZona([]);
    setCcList([]);
    setZonaList([]);
  }, [zonaIsDependent]);

  useEffect(() => {
    setSelectedDescripCuenta([]);
    setDescripCuentaList([]);
    if (selectedCategoria.length === 0) {
      return;
    }

    (async () => {
      const res = await getUncatchEndpointData({
        endpoint: 'reportes/gastos/descripCuenta',
        query: { selectedCategoria: selectedCategoria.map((obj) => obj.value) }
      });
      if (res && res.length > 0) {
        const data: DropdownProps[] = res.map((row: SimpleObject, idx: number) => ({
          key: `descripCuenta_${idx}`,
          value: row.accountId,
          text: row.accountDescription
        }));
        setDescripCuentaList(data);
      }
    })();
  }, [selectedCategoria]);

  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    const tableColumns = reporteGastosColumns.filter((c) =>
      selectedColumnas.find((s) => s.value === c.accessorKey)
    );

    selectedIndicators.forEach((i) => {
      const key = (i.value || '').toString().toLowerCase();
      const accessorKey = snakeToCamelCase(key);
      tableColumns.push({
        header: i.text,
        accessorKey,
        columnType: 'numeric',
        accessorFn: (row) => row[accessorKey],
        Cell: ({ row }) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            {formatStringNumber(row.original[accessorKey])}
          </div>
        ),
        aggregationFn: 'sum',
        AggregatedCell: ({ cell }) => {
          const sum = cell.getValue();
          return (
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              {sum ? formatStringNumber(sum?.toString(), 0, 2) : ''}
            </div>
          );
        },
        Footer: ({ table }: { table: any }) => {
          const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
          const total = rows.reduce(
            (acc: number, obj: SimpleObject) =>
              obj[accessorKey] ? acc + Number(obj[accessorKey]) : acc,
            0
          );
          return (
            <div style={{ display: 'flex', gap: 10, width: '100%', justifyContent: 'flex-end' }}>
              <Stack sx={{ fontSize: 15, color: 'green' }}>
                {formatStringNumber(total.toString(), 0, 0)}
              </Stack>
            </div>
          );
        }
      });
    });

    const entity: WebEntity<ReporteGastosType> = { ...E.ReporteGastosEntity, tableColumns };

    const columns = selectedColumnas.map((s) => s.value);
    const indicators = selectedIndicators.map((i) => i.value);
    const months = selectedMes.map((i) => i.value);

    const cc = selectedCc.map((i) => i.value);
    const zones = selectedZona.map((i) => i.value);
    const categories = selectedCategoria.map((i) => i.value);
    const accounts = selectedDescripCuenta.map((i) => i.value);

    return ReporteGastosCRUD(entity, {
      columns,
      indicators,
      months,
      cc,
      zones,
      categories,
      accounts
    });
  }, [
    selectedColumnas,
    selectedIndicators,
    selectedMes,
    selectedCc,
    selectedZona,
    selectedCategoria,
    selectedDescripCuenta
  ]);

  return (
    <Fragment>
      <div className='reporte-gastos-collapse-filters-container'>
        <div className='reporte-gastos-filters-section'>
          <h2 className='reporte-gastos-filters-subtitle'>Columnas</h2>
          <div className='reporte-gastos-filters-container'>
            <Dropdown
              options={columnasList}
              onChange={(_, data) => {
                const value = data.value as Array<string | number>;
                const updatedSelected = columnasList.filter((item) => value.includes(item.value));
                setSelectedColumnas(updatedSelected);
              }}
              value={selectedColumnas.map((item) => item.value)}
              loading={loading}
              disabled={loading}
              className='mb-3'
              placeholder='Columnas'
              fluid
              lazyLoad
              search
              selection
              multiple
              clearable
            />

            <Dropdown
              options={indicatorsList}
              onChange={(_, data) => {
                const value = data.value as Array<string | number>;
                const updatedSelected = indicatorsList.filter((item) => value.includes(item.value));
                setSelectedIndicators(updatedSelected);
              }}
              value={selectedIndicators.map((item) => item.value)}
              loading={loading}
              disabled={loading}
              className='mb-3'
              placeholder='Indicadores'
              fluid
              lazyLoad
              search
              selection
              multiple
              clearable
            />
          </div>
          <h2 className='reporte-gastos-filters-subtitle'>Filtros</h2>
          <div className='reporte-gastos-filters-container'>
            <Dropdown
              options={zonaList}
              onChange={(_, data) => {
                const value = data.value as Array<string | number>;
                const updatedSelected = zonaList.filter((item) => value.includes(item.value));
                setSelectedZona(updatedSelected);
              }}
              value={selectedZona.map((item) => item.value)}
              loading={loading}
              disabled={loading}
              className='mb-3'
              placeholder='Zona'
              fluid
              lazyLoad
              search
              selection
              multiple
              clearable
            />

            <Tooltip title='¿Que filtro es dependiente del otro?' followCursor>
              <FormControlLabel
                control={
                  <MaterialUISwitch
                    checked={zonaIsDependent}
                    value={zonaIsDependent}
                    onChange={(_, checked) => setZonaIsDependent(checked)}
                  />
                }
                label='¿Dependiente?'
                labelPlacement='top'
                slotProps={{
                  typography: { textAlign: 'center', fontWeight: 900, marginTop: -2 }
                }}
              />
            </Tooltip>

            <Dropdown
              options={ccList}
              onChange={(_, data) => {
                const value = data.value as Array<string | number>;
                const updatedSelected = ccList.filter((item) => value.includes(item.value));
                setSelectedCc(updatedSelected);
              }}
              value={selectedCc.map((item) => item.value)}
              loading={loading}
              disabled={loading}
              className='mb-3'
              placeholder='CC'
              fluid
              lazyLoad
              search
              selection
              multiple
              clearable
            />

            <Dropdown
              options={categoriaList}
              onChange={(_, data) => {
                const value = data.value as Array<string | number>;
                const updatedSelected = categoriaList.filter((item) => value.includes(item.value));
                setSelectedCategoria(updatedSelected);
              }}
              value={selectedCategoria.map((item) => item.value)}
              loading={loading}
              disabled={loading}
              className='mb-3'
              placeholder='Categoria'
              fluid
              lazyLoad
              search
              selection
              multiple
              clearable
            />

            <Dropdown
              options={descripCuentaList}
              onChange={(_, data) => {
                const value = data.value as Array<string | number>;
                const updatedSelected = descripCuentaList.filter((item) =>
                  value.includes(item.value)
                );
                setSelectedDescripCuenta(updatedSelected);
              }}
              value={selectedDescripCuenta.map((item) => item.value)}
              loading={loading}
              disabled={loading}
              className='mb-3'
              placeholder='Descrip_Cuenta'
              fluid
              lazyLoad
              search
              selection
              multiple
              clearable
            />

            <Dropdown
              options={mesList}
              onChange={(_, data) => {
                const value = data.value as Array<string | number>;
                const updatedSelected = mesList.filter((item) => value.includes(item.value));
                setSelectedMes(updatedSelected);
              }}
              value={selectedMes.map((item) => item.value)}
              loading={loading}
              disabled={loading}
              className='mb-3'
              placeholder='Mes'
              fluid
              lazyLoad
              search
              selection
              multiple
              clearable
            />
          </div>
        </div>
      </div>

      <CRUD />

      <div className='reporte-gastos-export-btn-container'>
        <ExportContainer
          containerId='expenses-chart'
          fileName='dinamica-gastos'
          disabled={
            entities.reporteGastos.list.length === 0 ||
            selectedIndicators.length === 0 ||
            selectedColumnas.length === 0
          }
          callbackBefore={() => {
            const titles = document.querySelectorAll('.reporte-gastos-chart-title');
            titles.forEach((title) => {
              const htmlTittle = title as HTMLElement;
              htmlTittle.style.display = 'block';
            });
          }}
          callbackAfter={() => {
            const titles = document.querySelectorAll('.reporte-gastos-chart-title');
            titles.forEach((title) => {
              const htmlTittle = title as HTMLElement;
              htmlTittle.style.display = 'none';
            });
          }}
        />
      </div>

      <div id='expenses-chart' className='reporte-gastos-chart-container'>
        <h2 className='reporte-gastos-chart-title'>Gastos</h2>
        <BarChartComponent
          data={entities.reporteGastos.list.map((row) => ({
            ...row,
            monthDate: isNaN(new Date(dateFormatFn(row.monthDate, 'MM-DD-YYYY', true)).getTime())
              ? ''
              : spanishMonthNames[
                  new Date(dateFormatFn(row.monthDate, 'MM-DD-YYYY', true)).getMonth()
                ]
          }))}
          indicators={selectedIndicators.map((s) => ({
            ...s,
            value: snakeToCamelCase((s.value || '').toString().toLowerCase())
          }))}
          columns={selectedColumnas}
        />
      </div>
    </Fragment>
  );
};

const BarChartComponent: FunctionComponent<{
  data: DataType[];
  indicators: DropdownProps[];
  columns: DropdownProps[];
}> = ({ data, indicators, columns }) => {
  const series: AllSeriesType[] = indicators.map((obj) => ({
    type: 'bar',
    dataKey: (obj.value || '').toString(),
    label: (obj.text || '').toString(),
    yAxisId: 'leftAxis'
  }));

  const combinations = Array.from(
    new Set(
      data.flatMap((row) => columns.map((column) => row[column.value.toString()]).join(' ; '))
    )
  );

  const dataset: DatasetType = combinations.map((combination) => {
    const filteredData = data.filter((row) =>
      columns.every(
        (column) =>
          row[column.value.toString()] === combination.split(' ; ')[columns.indexOf(column)]
      )
    );

    const result: DataType = { combination };

    indicators.forEach((indicator) => {
      const key = indicator.value.toString();
      const accessorKey = snakeToCamelCase(key);
      result[accessorKey] = filteredData.reduce((sum, row) => sum + Number(row[accessorKey]), 0);
    });

    return result;
  });

  return dataset.length > 0 ? (
    <ResponsiveChartContainer
      height={800}
      margin={{ top: 100, bottom: 100, left: 100, right: 0 }}
      dataset={dataset}
      series={series}
      xAxis={[
        {
          scaleType: 'band',
          dataKey: 'combination',
          tickPlacement: 'middle',
          labelStyle: { fontSize: 14 },
          tickLabelStyle: {
            angle: -45,
            textAnchor: 'end',
            fontSize: 12
          },
          valueFormatter: (value, context) => {
            const splitValues = value.split(' ; ');
            if (context.location === 'tooltip') {
              const formatted = columns
                .map((c, idx) => `${c.text}: ${splitValues[idx]}`)
                .join(' ; ');
              return formatted;
            } else if (context.location === 'tick') {
              const joinString = splitValues.join(' - ');
              if (joinString.length > 15) {
                return joinString.substring(0, 15) + '...';
              }
              return joinString;
            }
            return value;
          }
        }
      ]}
      yAxis={[
        {
          id: 'leftAxis',
          reverse: true,
          valueFormatter: (value, context) => {
            if (context.location === 'tick') {
              return formatStringNumber(value.toString());
            }
            return value;
          }
        }
      ]}>
      <BarPlot />
      <ChartsXAxis />
      <ChartsYAxis axisId='leftAxis' position='left' />
      <ChartsTooltip />
      <ChartsLegend />
    </ResponsiveChartContainer>
  ) : null;
};

const PieChartComponent: FunctionComponent<{ entities: EntitiesState }> = ({ entities }) => {
  const [zonaIsDependent_1, setZonaIsDependent_1] = useState<boolean>(true);
  const [zonaIsDependent_2, setZonaIsDependent_2] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const pieChartValues: Array<DropdownProps> = [
    { key: 'categoriesName_1', value: 'categoriesName', text: 'Categoria' },
    { key: 'categoriesName_2', value: 'zoneDescription', text: 'Zona' },
    { key: 'categoriesName_3', value: 'monthDate', text: 'Mes' }
  ];
  const [indicatorsList, setIndicatorsList] = useState<Array<DropdownProps>>([]);
  const [ccList_1, setCcList_1] = useState<Array<DropdownProps>>([]);
  const [ccList_2, setCcList_2] = useState<Array<DropdownProps>>([]);
  const [zonaList_1, setZonaList_1] = useState<Array<DropdownProps>>([]);
  const [zonaList_2, setZonaList_2] = useState<Array<DropdownProps>>([]);
  const [categoriaList, setCategoriaList] = useState<Array<DropdownProps>>([]);
  const [mesList, setMesList] = useState<Array<DropdownProps>>([]);

  const [selectedPieChartValue_1, setSelectedPieChartValue_1] = useState<DropdownProps | undefined>(
    pieChartValues[0]
  );
  const [selectedPieChartValue_2, setSelectedPieChartValue_2] = useState<DropdownProps | undefined>(
    pieChartValues[0]
  );
  const [selectedIndicator_1, setSelectedIndicator_1] = useState<DropdownProps | undefined>(
    undefined
  );
  const [selectedIndicator_2, setSelectedIndicator_2] = useState<DropdownProps | undefined>(
    undefined
  );
  const [selectedCc_1, setSelectedCc_1] = useState<Array<DropdownProps>>([]);
  const [selectedCc_2, setSelectedCc_2] = useState<Array<DropdownProps>>([]);
  const [selectedZona_1, setSelectedZona_1] = useState<Array<DropdownProps>>([]);
  const [selectedZona_2, setSelectedZona_2] = useState<Array<DropdownProps>>([]);
  const [selectedCategoria_1, setSelectedCategoria_1] = useState<Array<DropdownProps>>([]);
  const [selectedCategoria_2, setSelectedCategoria_2] = useState<Array<DropdownProps>>([]);
  const [selectedMes_1, setSelectedMes_1] = useState<Array<DropdownProps>>([]);
  const [selectedMes_2, setSelectedMes_2] = useState<Array<DropdownProps>>([]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const indicators = await getUncatchEndpointData({
        endpoint: 'reportes/gastos/indicadores'
      });
      if (indicators && indicators.length > 0) {
        const data: DropdownProps[] = indicators.map((row: SimpleObject, idx: number) => ({
          key: `indicator_${idx}`,
          value: row.columnName,
          text: row.indicador
        }));
        setIndicatorsList(data);
        const currentYear = new Date().getFullYear();
        setSelectedIndicator_1(data.find((d) => d.text.includes(`${currentYear.toString()}R`)));
        setSelectedIndicator_2(data.find((d) => d.text.includes(`${currentYear.toString()}P`)));
      }
    })();
    (async () => {
      const res = await getUncatchEndpointData({
        endpoint: 'reportes/gastos/categoria'
      });
      if (res && res.length > 0) {
        const data: DropdownProps[] = res.map((row: SimpleObject, idx: number) => ({
          key: `categoria_${idx}`,
          value: row.categoriesId,
          text: row.categoriesName
        }));
        setCategoriaList(data);
      }
    })();

    setMesList(
      spanishMonthNames.map((monthName, idx) => ({
        key: `mes_${idx}`,
        value: idx,
        text: monthName
      }))
    );
    const currentMonth = new Date().getMonth();
    const findCurrentMonth = spanishMonthNames[currentMonth];
    setSelectedMes_1(
      findCurrentMonth
        ? [{ key: `mes_${currentMonth}`, value: currentMonth, text: findCurrentMonth }]
        : []
    );
    setSelectedMes_2(
      findCurrentMonth
        ? [{ key: `mes_${currentMonth}`, value: currentMonth, text: findCurrentMonth }]
        : []
    );
    setLoading(false);
  }, []);

  useEffect(() => {
    const fetchZonas = async () => {
      setLoading(true);
      if (zonaIsDependent_1) {
        setZonaList_1([]);
        const res = await getUncatchEndpointData({ endpoint: 'reportes/gastos/zona' });

        if (res && res.length > 0) {
          const data: DropdownProps[] = res.map((row: SimpleObject, idx: number) => ({
            key: `zona_${idx}`,
            value: row.zoneId,
            text: row.zoneDescription
          }));
          setZonaList_1(data);
        }
      } else if (selectedCc_1.length > 0) {
        setZonaList_1([]);
        const res = await getUncatchEndpointData({
          endpoint: 'reportes/gastos/zona',
          query: { selectedCC: selectedCc_1.map((obj) => obj.value) }
        });

        if (res && res.length > 0) {
          const data: DropdownProps[] = res.map((row: SimpleObject, idx: number) => ({
            key: `zona_${idx}`,
            value: row.zoneId,
            text: row.zoneDescription
          }));
          setZonaList_1(data);
        }
      }
      setLoading(false);
    };

    fetchZonas();
  }, [zonaIsDependent_1, selectedCc_1]);

  useEffect(() => {
    const fetchCC = async () => {
      setLoading(true);
      if (!zonaIsDependent_1) {
        setCcList_1([]);
        const res = await getUncatchEndpointData({ endpoint: 'reportes/gastos/cc' });

        if (res && res.length > 0) {
          const data: DropdownProps[] = res.map((row: SimpleObject, idx: number) => ({
            key: `cc_${idx}`,
            value: row.costCenterId,
            text: `${row.costCenterSap} - ${row.costCenterName}`
          }));
          setCcList_1(data);
        }
      } else if (selectedZona_1.length > 0) {
        setCcList_1([]);
        const res = await getUncatchEndpointData({
          endpoint: 'reportes/gastos/cc',
          query: { selectedZona: selectedZona_1.map((obj) => obj.value) }
        });

        if (res && res.length > 0) {
          const data: DropdownProps[] = res.map((row: SimpleObject, idx: number) => ({
            key: `cc_${idx}`,
            value: row.costCenterId,
            text: `${row.costCenterSap} - ${row.costCenterName}`
          }));
          setCcList_1(data);
        }
      }
      setLoading(false);
    };

    fetchCC();
  }, [zonaIsDependent_1, selectedZona_1]);

  // Clean selected values when dependency changes
  useEffect(() => {
    setSelectedCc_1([]);
    setSelectedZona_1([]);
    setCcList_1([]);
    setZonaList_1([]);
  }, [zonaIsDependent_1]);

  useEffect(() => {
    const fetchZonas = async () => {
      setLoading(true);
      if (zonaIsDependent_2) {
        setZonaList_2([]);
        const res = await getUncatchEndpointData({ endpoint: 'reportes/gastos/zona' });

        if (res && res.length > 0) {
          const data: DropdownProps[] = res.map((row: SimpleObject, idx: number) => ({
            key: `zona_${idx}`,
            value: row.zoneId,
            text: row.zoneDescription
          }));
          setZonaList_2(data);
        }
      } else if (selectedCc_2.length > 0) {
        setZonaList_2([]);
        const res = await getUncatchEndpointData({
          endpoint: 'reportes/gastos/zona',
          query: { selectedCC: selectedCc_2.map((obj) => obj.value) }
        });

        if (res && res.length > 0) {
          const data: DropdownProps[] = res.map((row: SimpleObject, idx: number) => ({
            key: `zona_${idx}`,
            value: row.zoneId,
            text: row.zoneDescription
          }));
          setZonaList_2(data);
        }
      }
      setLoading(false);
    };

    fetchZonas();
  }, [zonaIsDependent_2, selectedCc_2]);

  useEffect(() => {
    const fetchCC = async () => {
      setLoading(true);
      if (!zonaIsDependent_2) {
        setCcList_2([]);
        const res = await getUncatchEndpointData({ endpoint: 'reportes/gastos/cc' });

        if (res && res.length > 0) {
          const data: DropdownProps[] = res.map((row: SimpleObject, idx: number) => ({
            key: `cc_${idx}`,
            value: row.costCenterId,
            text: `${row.costCenterSap} - ${row.costCenterName}`
          }));
          setCcList_2(data);
        }
      } else if (selectedZona_2.length > 0) {
        setCcList_2([]);
        const res = await getUncatchEndpointData({
          endpoint: 'reportes/gastos/cc',
          query: { selectedZona: selectedZona_2.map((obj) => obj.value) }
        });

        if (res && res.length > 0) {
          const data: DropdownProps[] = res.map((row: SimpleObject, idx: number) => ({
            key: `cc_${idx}`,
            value: row.costCenterId,
            text: `${row.costCenterSap} - ${row.costCenterName}`
          }));
          setCcList_2(data);
        }
      }
      setLoading(false);
    };

    fetchCC();
  }, [zonaIsDependent_2, selectedZona_2]);

  // Clean selected values when dependency changes
  useEffect(() => {
    setSelectedCc_2([]);
    setSelectedZona_2([]);
    setCcList_2([]);
    setZonaList_2([]);
  }, [zonaIsDependent_2]);

  const CRUD1: ConnectedComponent<any, any> = useMemo(() => {
    const tableColumns: ExtendedColumnDef<ReportePieChart>[] = [];

    if (selectedPieChartValue_1) {
      const accessorKey = selectedPieChartValue_1.value as string;
      if (accessorKey === 'monthDate') {
        tableColumns.push({
          header: selectedPieChartValue_1.text,
          accessorKey,
          columnType: 'text',
          valueToExport: (row) => {
            const date = new Date(dateFormatFn(row.monthDate, 'MM-DD-YYYY', true));
            if (isNaN(date.getTime())) {
              return '';
            }
            const month = date.getMonth();
            return spanishMonthNames[month];
          },
          accessorFn: (row) => {
            const date = new Date(dateFormatFn(row.monthDate, 'MM-DD-YYYY', true));
            if (isNaN(date.getTime())) {
              return '';
            }
            const month = date.getMonth();
            return spanishMonthNames[month];
          }
        });
      } else {
        tableColumns.push({
          header: selectedPieChartValue_1.text,
          accessorKey,
          columnType: 'text'
        });
      }
    }

    if (selectedIndicator_1) {
      tableColumns.push({
        header: selectedIndicator_1.text,
        accessorKey: 'total',
        columnType: 'numeric',
        accessorFn: (row) => row.total,
        Cell: ({ row }) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            {formatStringNumber(row.original.total)}
          </div>
        ),
        Footer: ({ table }: { table: any }) => {
          const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
          const total = rows.reduce(
            (acc: number, obj: SimpleObject) => (obj.total ? acc + Number(obj.total) : acc),
            0
          );
          return (
            <div style={{ display: 'flex', gap: 10, width: '100%', justifyContent: 'flex-end' }}>
              <Stack sx={{ fontSize: 15, color: 'green' }}>
                {formatStringNumber(total.toString(), 0, 0)}
              </Stack>
            </div>
          );
        }
      });
    }

    const entity: WebEntity<ReportePieChart> = { ...E.GastosPieChart1Entity, tableColumns };

    const months = selectedMes_1.map((i) => i.value);
    const cc = selectedCc_1.map((i) => i.value);
    const zones = selectedZona_1.map((i) => i.value);
    const categories = selectedCategoria_1.map((i) => i.value);

    return GastosPieChartCRUD(entity, {
      column: selectedPieChartValue_1?.value,
      indicator: selectedIndicator_1?.value,
      months,
      cc,
      zones,
      categories
    });
  }, [
    selectedIndicator_1,
    selectedPieChartValue_1,
    selectedMes_1,
    selectedCc_1,
    selectedZona_1,
    selectedCategoria_1
  ]);

  const CRUD2: ConnectedComponent<any, any> = useMemo(() => {
    const tableColumns: ExtendedColumnDef<ReportePieChart>[] = [];

    if (selectedPieChartValue_2) {
      const accessorKey = selectedPieChartValue_2.value as string;
      if (accessorKey === 'monthDate') {
        tableColumns.push({
          header: selectedPieChartValue_2.text,
          accessorKey,
          columnType: 'text',
          valueToExport: (row) => {
            const date = new Date(dateFormatFn(row.monthDate, 'MM-DD-YYYY', true));
            if (isNaN(date.getTime())) {
              return '';
            }
            const month = date.getMonth();
            return spanishMonthNames[month];
          },
          accessorFn: (row) => {
            const date = new Date(dateFormatFn(row.monthDate, 'MM-DD-YYYY', true));
            if (isNaN(date.getTime())) {
              return '';
            }
            const month = date.getMonth();
            return spanishMonthNames[month];
          }
        });
      } else {
        tableColumns.push({
          header: selectedPieChartValue_2.text,
          accessorKey,
          columnType: 'text'
        });
      }
    }

    if (selectedIndicator_2) {
      tableColumns.push({
        header: selectedIndicator_2.text,
        accessorKey: 'total',
        columnType: 'numeric',
        accessorFn: (row) => row.total,
        Cell: ({ row }) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            {formatStringNumber(row.original.total)}
          </div>
        ),
        Footer: ({ table }: { table: any }) => {
          const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
          const total = rows.reduce(
            (acc: number, obj: SimpleObject) => (obj.total ? acc + Number(obj.total) : acc),
            0
          );
          return (
            <div style={{ display: 'flex', gap: 10, width: '100%', justifyContent: 'flex-end' }}>
              <Stack sx={{ fontSize: 15, color: 'green' }}>
                {formatStringNumber(total.toString(), 0, 0)}
              </Stack>
            </div>
          );
        }
      });
    }

    const entity: WebEntity<ReportePieChart> = { ...E.GastosPieChart2Entity, tableColumns };

    const months = selectedMes_2.map((i) => i.value);
    const cc = selectedCc_2.map((i) => i.value);
    const zones = selectedZona_2.map((i) => i.value);
    const categories = selectedCategoria_2.map((i) => i.value);

    return GastosPieChartCRUD(entity, {
      column: selectedPieChartValue_2?.value,
      indicator: selectedIndicator_2?.value,
      months,
      cc,
      zones,
      categories
    });
  }, [
    selectedIndicator_2,
    selectedPieChartValue_2,
    selectedMes_2,
    selectedCc_2,
    selectedZona_2,
    selectedCategoria_2
  ]);

  const [pieChartData_1, setPieChartData_1] = useState<SimpleObject[]>([]);
  const [absTotalPieChartData_1, setAbsTotalPieChartData_1] = useState<number>(0);
  const [pieChartData_2, setPieChartData_2] = useState<SimpleObject[]>([]);
  const [absTotalPieChartData_2, setAbsTotalPieChartData_2] = useState<number>(0);

  useEffect(() => {
    const data = entities.gastosPieChart1.list;
    const serie = selectedPieChartValue_1?.value as string;

    setAbsTotalPieChartData_1(data.reduce((sum, row) => sum + Math.abs(Number(row.total)), 0));

    const processedData: SimpleObject[] = data.map((row, idx) => {
      if (
        serie === 'monthDate' &&
        !isNaN(new Date(dateFormatFn(row.monthDate, 'MM-DD-YYYY', true)).getTime())
      ) {
        const date = new Date(dateFormatFn(row.monthDate, 'MM-DD-YYYY', true));
        const month = date.getMonth();
        return {
          id: idx,
          value: Math.abs(Number(row.total)),
          label: spanishMonthNames[month],
          originalValue: Number(row.total)
        };
      }
      return {
        id: idx,
        value: Math.abs(Number(row.total)),
        label: row[serie],
        originalValue: Number(row.total)
      };
    });
    setPieChartData_1(processedData);
  }, [entities.gastosPieChart1.list, selectedPieChartValue_1?.value, selectedIndicator_1?.value]);

  useEffect(() => {
    const data = entities.gastosPieChart2.list;
    const serie = selectedPieChartValue_2?.value as string;

    setAbsTotalPieChartData_2(data.reduce((sum, row) => sum + Math.abs(Number(row.total)), 0));

    const processedData: SimpleObject[] = data.map((row, idx) => {
      if (
        serie === 'monthDate' &&
        !isNaN(new Date(dateFormatFn(row.monthDate, 'MM-DD-YYYY', true)).getTime())
      ) {
        const date = new Date(dateFormatFn(row.monthDate, 'MM-DD-YYYY', true));
        const month = date.getMonth();
        return {
          id: idx,
          value: Math.abs(Number(row.total)),
          label: spanishMonthNames[month],
          originalValue: Number(row.total)
        };
      }

      return {
        id: idx,
        value: Math.abs(Number(row.total)),
        label: row[serie],
        originalValue: Number(row.total)
      };
    });

    setPieChartData_2(processedData);
  }, [entities.gastosPieChart2.list, selectedPieChartValue_2?.value, selectedIndicator_2?.value]);

  const CRUDcomparison: ConnectedComponent<any, any> | null = useMemo(() => {
    const CRUD_1List = entities.gastosPieChart1.list;
    const value_1 = selectedPieChartValue_1;
    const serie_1 = selectedIndicator_1;
    const CRUD_2List = entities.gastosPieChart2.list;
    const value_2 = selectedPieChartValue_2;
    const serie_2 = selectedIndicator_2;

    if (
      CRUD_1List.length === 0 ||
      CRUD_2List.length === 0 ||
      !value_1 ||
      !serie_1 ||
      !value_2 ||
      !serie_2 ||
      value_1?.value !== value_2?.value
    )
      return null;

    const tableColumns: ExtendedColumnDef<ReportePieChart>[] = [
      {
        header: value_1.text,
        accessorKey: value_1.value.toString(),
        columnType: 'text'
      },
      {
        header: serie_1.text,
        accessorKey: 'total_1',
        columnType: 'numeric',
        accessorFn: (row) => row.total_1,
        Cell: ({ row }) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            {formatStringNumber(row.original.total_1)}
          </div>
        )
      },
      {
        header: serie_2.text,
        accessorKey: 'total_2',
        columnType: 'numeric',
        accessorFn: (row) => row.total_2,
        Cell: ({ row }) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            {formatStringNumber(row.original.total_2)}
          </div>
        )
      },
      {
        header: 'Variación',
        accessorKey: 'comparison',
        columnType: 'percentage',
        accessorFn: (row) => row.comparison,
        Cell: ({ row }) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            {formatStringNumber((Number(row.original.comparison) * 100).toString(), 0, 2)} %
          </div>
        )
      }
    ];

    const entity: WebEntity<ReportePieChart> = { ...E.GastosPieCharComparisonEntity, tableColumns };
    return GastosPieChartComparisonCRUD(entity);
  }, [
    entities.gastosPieChart1.list,
    selectedPieChartValue_1,
    selectedIndicator_1,
    entities.gastosPieChart2.list,
    selectedPieChartValue_2,
    selectedIndicator_2
  ]);

  const dispatch = useDispatch();

  useEffect(() => {
    const value_1 = selectedPieChartValue_1;
    const serie_1 = selectedIndicator_1;
    const value_2 = selectedPieChartValue_2;
    const serie_2 = selectedIndicator_2;

    if (
      entities.gastosPieChart1.list.length > 0 &&
      entities.gastosPieChart2.list.length > 0 &&
      value_1 &&
      serie_1 &&
      value_2 &&
      serie_2 &&
      value_1?.value === value_2?.value
    ) {
      const CRUD_1List = entities.gastosPieChart1.list.map((row) => {
        if (value_1.value === 'monthDate') {
          const date = new Date(dateFormatFn(row.monthDate, 'MM-DD-YYYY', true));
          if (isNaN(date.getTime())) {
            return row;
          }
          const month = date.getMonth();
          return { ...row, monthDate: spanishMonthNames[month] };
        }
        return row;
      });

      const CRUD_2List = entities.gastosPieChart2.list.map((row) => {
        if (value_2.value === 'monthDate') {
          const date = new Date(dateFormatFn(row.monthDate, 'MM-DD-YYYY', true));
          if (isNaN(date.getTime())) {
            return row;
          }
          const month = date.getMonth();
          return { ...row, monthDate: spanishMonthNames[month] };
        }
        return row;
      });

      const allValues = Array.from(
        new Set([
          ...CRUD_1List.map((row) => row[value_1.value]),
          ...CRUD_2List.map((row) => row[value_1.value])
        ])
      ).sort((a, b) => {
        const indexA =
          value_1.value === 'monthDate' ? spanishMonthNames.findIndex((m) => m === a) : a;
        const indexB =
          value_1.value === 'monthDate' ? spanishMonthNames.findIndex((m) => m === b) : b;

        if (indexA > indexB) {
          return 1;
        }
        if (indexA < indexB) {
          return -1;
        }

        return 0;
      });

      const data = allValues.map((value) => {
        const find_1 = CRUD_1List.find((row) => row[value_1.value] === value);
        const find_2 = CRUD_2List.find((row) => row[value_2.value] === value);

        const serie_1Value = find_1 ? Number(find_1.total) : 0;
        const serie_2Value = find_2 ? Number(find_2.total) : 0;

        return {
          [value_1.value]: value,
          total_1: serie_1Value,
          total_2: serie_2Value,
          comparison: !serie_2Value ? 0 : (serie_1Value / serie_2Value - 1) * -1
        };
      });

      dispatch(loadEntityDataAction(E.GastosPieCharComparisonEntity.name)(data as any));
    }
  }, [
    dispatch,
    entities.gastosPieChart1.list,
    selectedPieChartValue_1,
    selectedIndicator_1,
    entities.gastosPieChart2.list,
    selectedPieChartValue_2,
    selectedIndicator_2
  ]);

  return (
    <div className='reporte-gastos-pie-chart-page-container'>
      <div className='reporte-gastos-pie-chart-section'>
        <div className='reporte-gastos-pie-chart-container'>
          <Dropdown
            options={pieChartValues}
            onChange={(_, data) => {
              const value = data.value as string;
              const updatedSelected = pieChartValues.find((item) => item.value === value);
              setSelectedPieChartValue_1(updatedSelected);
            }}
            value={selectedPieChartValue_1 ? selectedPieChartValue_1.value : undefined}
            loading={loading}
            disabled={loading}
            className='mb-3'
            placeholder='Valor'
            fluid
            lazyLoad
            search
            selection
            clearable
          />
          <Dropdown
            options={indicatorsList}
            onChange={(_, data) => {
              const value = data.value as string;
              const updatedSelected = indicatorsList.find((item) => item.value === value);
              setSelectedIndicator_1(updatedSelected);
            }}
            value={selectedIndicator_1 ? selectedIndicator_1.value : undefined}
            loading={loading}
            disabled={loading}
            className='mb-3'
            placeholder='Serie'
            fluid
            lazyLoad
            search
            selection
            clearable
          />
          <Dropdown
            options={zonaList_1}
            onChange={(_, data) => {
              const value = data.value as Array<string | number>;
              const updatedSelected = zonaList_1.filter((item) => value.includes(item.value));
              setSelectedZona_1(updatedSelected);
            }}
            value={selectedZona_1.map((item) => item.value)}
            loading={loading}
            disabled={loading}
            className='mb-3'
            placeholder='Zona'
            fluid
            lazyLoad
            search
            selection
            multiple
            clearable
          />
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Tooltip title='¿Que filtro es dependiente del otro?' followCursor>
              <FormControlLabel
                control={
                  <MaterialUISwitch
                    checked={zonaIsDependent_1}
                    value={zonaIsDependent_1}
                    onChange={(_, checked) => setZonaIsDependent_1(checked)}
                  />
                }
                label='¿Dependiente?'
                labelPlacement='top'
                slotProps={{
                  typography: { textAlign: 'center', fontWeight: 900, marginTop: -2 }
                }}
              />
            </Tooltip>
          </div>
          <Dropdown
            options={ccList_1}
            onChange={(_, data) => {
              const value = data.value as Array<string | number>;
              const updatedSelected = ccList_1.filter((item) => value.includes(item.value));
              setSelectedCc_1(updatedSelected);
            }}
            value={selectedCc_1.map((item) => item.value)}
            loading={loading}
            disabled={loading}
            className='mb-3'
            placeholder='CC'
            fluid
            lazyLoad
            search
            selection
            multiple
            clearable
          />
          <Dropdown
            options={categoriaList}
            onChange={(_, data) => {
              const value = data.value as Array<string | number>;
              const updatedSelected = categoriaList.filter((item) => value.includes(item.value));
              setSelectedCategoria_1(updatedSelected);
            }}
            value={selectedCategoria_1.map((item) => item.value)}
            loading={loading}
            disabled={loading}
            className='mb-3'
            placeholder='Categoria'
            fluid
            lazyLoad
            search
            selection
            multiple
            clearable
          />
          <Dropdown
            options={mesList}
            onChange={(_, data) => {
              const value = data.value as Array<string | number>;
              const updatedSelected = mesList.filter((item) => value.includes(item.value));
              setSelectedMes_1(updatedSelected);
            }}
            value={selectedMes_1.map((item) => item.value)}
            loading={loading}
            disabled={loading}
            className='mb-3'
            placeholder='Mes'
            fluid
            lazyLoad
            search
            selection
            multiple
            clearable
          />
          <CRUD1 />

          <div className='reporte-gastos-export-btn-container'>
            <ExportContainer
              containerId='pie-chart-1'
              fileName='grafico-torta-1'
              disabled={pieChartData_1.length === 0}
              callbackBefore={() => {
                const titles = document.querySelectorAll('.reporte-gastos-chart-title');
                titles.forEach((title) => {
                  const htmlTittle = title as HTMLElement;
                  htmlTittle.style.display = 'block';
                });
              }}
              callbackAfter={() => {
                const titles = document.querySelectorAll('.reporte-gastos-chart-title');
                titles.forEach((title) => {
                  const htmlTittle = title as HTMLElement;
                  htmlTittle.style.display = 'none';
                });
              }}
            />
          </div>

          <div id='pie-chart-1' className='reporte-gastos-pie-chart-wrapper'>
            <h2 className='reporte-gastos-chart-title'>Gastos</h2>
            <PieChart
              loading={loading}
              series={[
                {
                  data: pieChartData_1.map((obj) => ({
                    id: obj.id,
                    value: obj.value,
                    label: obj.label
                  })),
                  highlightScope: { fade: 'global', highlight: 'item' },
                  faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                  valueFormatter: (_, { dataIndex }) => {
                    const { originalValue } = pieChartData_1[dataIndex];
                    const formattedOriginalValue = formatStringNumber(originalValue);
                    const percentage = !absTotalPieChartData_1
                      ? 0
                      : (originalValue / absTotalPieChartData_1) * 100;
                    const formattedPercentage = formatStringNumber(percentage.toString(), 0, 2);
                    return `${formattedOriginalValue} (${formattedPercentage}%)`;
                  }
                }
              ]}
              height={pieChartData_1.length > 16 ? 400 + (pieChartData_1.length - 16) * 24 : 400}
              margin={{ top: 0, bottom: 0, left: 0, right: 250 }}
              slotProps={{
                legend: {
                  direction: 'column',
                  position: { vertical: 'middle', horizontal: 'right' },
                  padding: 0,
                  itemMarkWidth: 10,
                  itemMarkHeight: 9,
                  markGap: 5,
                  itemGap: 10
                }
              }}
            />
          </div>
        </div>

        <div className='reporte-gastos-pie-chart-container'>
          <Dropdown
            options={pieChartValues}
            onChange={(_, data) => {
              const value = data.value as string;
              const updatedSelected = pieChartValues.find((item) => item.value === value);
              setSelectedPieChartValue_2(updatedSelected);
            }}
            value={selectedPieChartValue_2 ? selectedPieChartValue_2.value : undefined}
            loading={loading}
            disabled={loading}
            className='mb-3'
            placeholder='Valor'
            fluid
            lazyLoad
            search
            selection
            clearable
          />
          <Dropdown
            options={indicatorsList}
            onChange={(_, data) => {
              const value = data.value as string;
              const updatedSelected = indicatorsList.find((item) => item.value === value);
              setSelectedIndicator_2(updatedSelected);
            }}
            value={selectedIndicator_2 ? selectedIndicator_2.value : undefined}
            loading={loading}
            disabled={loading}
            className='mb-3'
            placeholder='Serie'
            fluid
            lazyLoad
            search
            selection
            clearable
          />
          <Dropdown
            options={zonaList_2}
            onChange={(_, data) => {
              const value = data.value as Array<string | number>;
              const updatedSelected = zonaList_2.filter((item) => value.includes(item.value));
              setSelectedZona_2(updatedSelected);
            }}
            value={selectedZona_2.map((item) => item.value)}
            loading={loading}
            disabled={loading}
            className='mb-3'
            placeholder='Zona'
            fluid
            lazyLoad
            search
            selection
            multiple
            clearable
          />
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Tooltip title='¿Que filtro es dependiente del otro?' followCursor>
              <FormControlLabel
                control={
                  <MaterialUISwitch
                    checked={zonaIsDependent_2}
                    value={zonaIsDependent_2}
                    onChange={(_, checked) => setZonaIsDependent_2(checked)}
                  />
                }
                label='¿Dependiente?'
                labelPlacement='top'
                slotProps={{
                  typography: { textAlign: 'center', fontWeight: 900, marginTop: -2 }
                }}
              />
            </Tooltip>
          </div>
          <Dropdown
            options={ccList_2}
            onChange={(_, data) => {
              const value = data.value as Array<string | number>;
              const updatedSelected = ccList_2.filter((item) => value.includes(item.value));
              setSelectedCc_2(updatedSelected);
            }}
            value={selectedCc_2.map((item) => item.value)}
            loading={loading}
            disabled={loading}
            className='mb-3'
            placeholder='CC'
            fluid
            lazyLoad
            search
            selection
            multiple
            clearable
          />
          <Dropdown
            options={categoriaList}
            onChange={(_, data) => {
              const value = data.value as Array<string | number>;
              const updatedSelected = categoriaList.filter((item) => value.includes(item.value));
              setSelectedCategoria_2(updatedSelected);
            }}
            value={selectedCategoria_2.map((item) => item.value)}
            loading={loading}
            disabled={loading}
            className='mb-3'
            placeholder='Categoria'
            fluid
            lazyLoad
            search
            selection
            multiple
            clearable
          />
          <Dropdown
            options={mesList}
            onChange={(_, data) => {
              const value = data.value as Array<string | number>;
              const updatedSelected = mesList.filter((item) => value.includes(item.value));
              setSelectedMes_2(updatedSelected);
            }}
            value={selectedMes_2.map((item) => item.value)}
            loading={loading}
            disabled={loading}
            className='mb-3'
            placeholder='Mes'
            fluid
            lazyLoad
            search
            selection
            multiple
            clearable
          />
          <CRUD2 />

          <div className='reporte-gastos-export-btn-container'>
            <ExportContainer
              containerId='pie-chart-2'
              fileName='grafico-torta-2'
              disabled={pieChartData_2.length === 0}
              callbackBefore={() => {
                const titles = document.querySelectorAll('.reporte-gastos-chart-title');
                titles.forEach((title) => {
                  const htmlTittle = title as HTMLElement;
                  htmlTittle.style.display = 'block';
                });
              }}
              callbackAfter={() => {
                const titles = document.querySelectorAll('.reporte-gastos-chart-title');
                titles.forEach((title) => {
                  const htmlTittle = title as HTMLElement;
                  htmlTittle.style.display = 'none';
                });
              }}
            />
          </div>

          <div id='pie-chart-2' className='reporte-gastos-pie-chart-wrapper'>
            <h2 className='reporte-gastos-chart-title'>Gastos</h2>
            <PieChart
              loading={loading}
              series={[
                {
                  data: pieChartData_2.map((obj) => ({
                    id: obj.id,
                    value: obj.value,
                    label: obj.label
                  })),
                  highlightScope: { fade: 'global', highlight: 'item' },
                  faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                  valueFormatter: (_, { dataIndex }) => {
                    const { originalValue } = pieChartData_2[dataIndex];
                    const formattedOriginalValue = formatStringNumber(originalValue);
                    const percentage = !absTotalPieChartData_2
                      ? 0
                      : (originalValue / absTotalPieChartData_2) * 100;
                    const formattedPercentage = formatStringNumber(percentage.toString(), 0, 2);
                    return `${formattedOriginalValue} (${formattedPercentage}%)`;
                  }
                }
              ]}
              height={pieChartData_2.length > 16 ? 400 + (pieChartData_2.length - 16) * 24 : 400}
              margin={{ top: 0, bottom: 0, left: 0, right: 250 }}
              slotProps={{
                legend: {
                  direction: 'column',
                  position: { vertical: 'middle', horizontal: 'right' },
                  padding: 0,
                  itemMarkWidth: 10,
                  itemMarkHeight: 9,
                  markGap: 5,
                  itemGap: 10
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className='reporte-gastos-pie-chart-container'>
        {!CRUDcomparison ? (
          <Alert severity='warning'>No es posible realizar una comparación</Alert>
        ) : (
          <>
            <CRUDcomparison />

            <div className='reporte-gastos-export-btn-container'>
              <ExportContainer
                containerId='comparison-chart'
                fileName='variacion'
                disabled={entities.gastosPieChartComparison.list.length === 0}
                callbackBefore={() => {
                  const titles = document.querySelectorAll('.reporte-gastos-chart-title');
                  titles.forEach((title) => {
                    const htmlTittle = title as HTMLElement;
                    htmlTittle.style.display = 'block';
                  });
                }}
                callbackAfter={() => {
                  const titles = document.querySelectorAll('.reporte-gastos-chart-title');
                  titles.forEach((title) => {
                    const htmlTittle = title as HTMLElement;
                    htmlTittle.style.display = 'none';
                  });
                }}
              />
            </div>

            <div id='comparison-chart' className='reporte-gastos-pie-chart-wrapper'>
              <h2 className='reporte-gastos-chart-title'>Gastos</h2>
              <ResponsiveChartContainer
                height={800}
                margin={{ top: 100, bottom: 100, left: 100, right: 50 }}
                dataset={entities.gastosPieChartComparison.list.map((row) => ({
                  ...row,
                  comparison: Number(row.comparison) * 100
                }))}
                series={[
                  {
                    type: 'bar',
                    dataKey: 'total_1',
                    label: (selectedIndicator_1?.text || '').toString(),
                    yAxisId: 'leftAxis'
                  },
                  {
                    type: 'bar',
                    dataKey: 'total_2',
                    label: (selectedIndicator_2?.text || '').toString(),
                    yAxisId: 'leftAxis'
                  },
                  {
                    type: 'line',
                    dataKey: 'comparison',
                    label: 'Variación (%)',
                    yAxisId: 'rightAxis',
                    valueFormatter: (value) => {
                      return `${formatStringNumber((value || '0').toString(), 0, 2)} %`;
                    }
                  }
                ]}
                xAxis={[
                  {
                    scaleType: 'band',
                    dataKey: (selectedPieChartValue_1?.value || '').toString(),
                    tickPlacement: 'middle',
                    labelStyle: { fontSize: 14 },
                    tickLabelStyle: {
                      angle: -45,
                      textAnchor: 'end',
                      fontSize: 12
                    },
                    valueFormatter: (value, context) => {
                      if (context.location === 'tick') {
                        if (value && value.length > 15) {
                          return value.substring(0, 15) + '...';
                        }
                        return value;
                      }
                      return value;
                    }
                  }
                ]}
                yAxis={[
                  {
                    id: 'leftAxis',
                    reverse: true,
                    valueFormatter: (value, context) => {
                      if (context.location === 'tick') {
                        return formatStringNumber(value.toString());
                      }
                      return value;
                    }
                  },
                  {
                    id: 'rightAxis',
                    valueFormatter: (value) => {
                      return `${value} %`;
                    }
                  }
                ]}>
                <BarPlot />
                <LinePlot />
                <ChartsXAxis />
                <ChartsYAxis axisId='leftAxis' position='left' />
                <ChartsYAxis axisId='rightAxis' position='right' />
                <ChartsTooltip />
                <ChartsLegend position={{ horizontal: 'middle', vertical: 'top' }} />
              </ResponsiveChartContainer>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
